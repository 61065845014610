import React, { useState } from 'react';
import axios from '../../../api/management_api';
import ReactDataTable from './ReactDataTable';
import ErrorBody from '../../common/error/ErrorBody';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

function Staging() {

    const [error, setError] = useState();
    const [info, setInfo] = useState([]);
    const [load, setLoad] = useState(false);
    const [address, setAddress] = useState({
        houseNumber: "",
        houseLetter: "",
        side: "",
        floor: "",
        streetName: "",
        postCode: ""
    });

    const resetAddress = () => {
        setAddress({
            houseNumber: "",
            houseLetter: "",
            side: "",
            floor: "",
            streetName: "",
            postCode: ""
        });
    }

    const handleAddress = (e) => {
        let name = e.target.name;
        let value = e.target.value.trim();
        setAddress(pre => ({ ...pre, [name]: value }));
    }

    const findStaging = (e) => {
        e.preventDefault();
        setLoad(true);
        setError("");
        setInfo([]);
        axios.post("/v2/transaction/getStagingInventory", address)
            .then(res => {
                if (res.data.length === 0) {
                    setError("Not Data Found");
                } else {
                    setInfo(res.data);
                }
                setLoad(false);
            }).catch((err) => {
                if (err.message.includes("504")) {
                    setError("Request timeout. Please retry");
                } else if (err.message.includes("500")) {
                    setError("Internal error");
                } else if (err.message.includes("401")) {
                    setError("Token expire. Please refresh the page.");
                } else {
                    setError("Bad Request");
                }
                setLoad(false);
            });
    }


    return (
        <div>
            <div className="card">
                <h5 className="card-header">Staging Inventory</h5>
                <div className="div-table my-2">
                    <div className="form-row">
                        <div className="div-table col-sm-12 col-md-12 mx-auto" style={{ background: "#f0f1f375" }}>
                            <div className="form-row">
                                <div className="form-group col-sm-2 col-md-2">
                                    <label htmlFor="madId">Street Name*</label>
                                    <input type="text" className="form-control form-control-sm" name="streetName"
                                        value={address.streetName} onChange={handleAddress} />
                                </div>
                                <div className="form-group col-sm-2 col-md-2">
                                    <label htmlFor="installationId">House no*</label>
                                    <input type="text" className="form-control form-control-sm" name="houseNumber"
                                        value={address.houseNumber} onChange={handleAddress} />
                                </div>
                                <div className="form-group col-sm-2 col-md-2">
                                    <label htmlFor="installationId">House letter</label>
                                    <input type="text" className="form-control form-control-sm" name="houseLetter"
                                        value={address.houseLetter} onChange={handleAddress} />
                                </div>
                                <div className="form-group col-sm-2 col-md-2">
                                    <label htmlFor="installationId">Floor</label>
                                    <input type="text" className="form-control form-control-sm" name="floor"
                                        value={address.floor} onChange={handleAddress} />
                                </div>
                                <div className="form-group col-sm-2 col-md-2">
                                    <label htmlFor="installationId">Side</label>
                                    <input type="text" className="form-control form-control-sm" name="side"
                                        value={address.side} onChange={handleAddress} />
                                </div>
                                <div className="form-group col-sm-2 col-md-2">
                                    <label htmlFor="installationId">Post Code*</label>
                                    <input type="text" className="form-control form-control-sm" name="postCode"
                                        value={address.postCode} onChange={handleAddress} maxLength="4" />
                                </div>

                            </div>
                            <div className="form-row">
                                <div className="form-group col-sm text-center">
                                    <button type="submit" id="search-btn" className="btn btn-info"
                                        disabled={!address.streetName || !address.postCode || !address.houseNumber}
                                        onClick={(e) => findStaging(e)}>Search</button>
                                    <button id="search-btn" className="btn btn-danger ml-2"
                                        onClick={() => resetAddress()}>Reset</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="div-table mb-3">
                    {error ? <ErrorBody errorMessage={error} /> :
                        info.length > 0 ? <ReactDataTable rowData={info} /> :
                            <div className="text-center p-4 text-wrap">
                                <FontAwesomeIcon className="mb-3" icon={faSearch} size="5x" color="rgb(87, 179, 193)"
                                    spin={load ? true : false} />
                                <h2 style={{ color: "rgb(73, 80, 87)" }}>
                                    {load ? "Loading" : "Search for Staging"}</h2>
                            </div>}
                </div>
            </div>
        </div>
    );
}

export default Staging;
export const msalConfig = {
    auth: {
        clientId: '4bffc75a-3849-4f00-88df-bdd6ccc4fee9',
        authority: window.__env__.REACT_APP_PING_AUTHORITY,
        redirectUri:  window.__env__.REACT_APP_PING_REDIRECT
    },
    cache: {
        cacheLocation: 'sessionStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: ["openid", "profile", "email", "offline_access"]
};
import axios from 'axios';
import {env} from './env';
import {getToken} from './outhApi';

const AuthAxios = axios.create({
    baseURL: window.__env__.REACT_APP_BASEURL,
    headers: { 'X-Requested-With': 'XMLHttpRequest', 'Content-Type': 'application/json' },
})

AuthAxios.interceptors.request.use(req => {
    // console.log(`token ${getToken()}`);
    req.headers.authorization = `Bearer ${getToken()}`;
    return req;
});

AuthAxios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    console.log(error);
    if (error.response && error.response.status) {
        error = {...error, message: errorMsg[error.response.status]};
    } else {
        if (error.message.includes('Network Error')) {
            return Promise.reject(error);
        } else {
            error = {...error, message: "Something went wrong."};
        }
    }
    return Promise.reject(error);
});

export default AuthAxios;

const errorMsg = {
    400: "Bad Request",
    401: "Unauthorized. Please refresh the page to renew token or contact to administrator for access.",
    403: "Forbidden. Please contact to administrator for access.",
    404: "URL Not Found",
    500: "Internal Server Error",
    501: "Not Implemented",
    502: "Bad Gateway",
    503: "Service Unavailable",
    504: "Request Timeout. Please retry with minimum records per page."
}

export const catalogTech = ['H1', 'GPON', 'P2P'];

export const hasAccess = () => {
    if (getToken()) {
        let roles = JSON.parse(atob(getToken().split(".")[1])).roles;
        if (roles) {
            if (typeof roles === "string") {
                return roles.includes("OPERATIONAL");
            } else {
                return roles.find(role => role.includes("OPERATIONAL")) ? true : false;
            }
        } else {
            return false;
        }

    }
}
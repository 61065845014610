import React from 'react';
import ErrImg from '../../../asset/image/system.jpg';

function ErrorBody({ errorMessage }) {
    return (
        <div className="error-class">
            <div>
                <img src={ErrImg} alt='error' />
            </div>
            <div>
                <h1>Error....!</h1>
                <h3>{errorMessage}</h3>
            </div>
        </div>
    );
}

export default ErrorBody;
import React, { useState } from 'react';
import { vendors } from '../../../data/constant';

export default function AiTable({ rowData }) {
    const [select, setSelect] = useState();
    return (
        <div style={{ position: 'relative', overflow: 'scroll', textAlign: 'center', maxHeight: "70vh" }}>
            <table className="table table-sm table-striped table-bordered">
                <thead className="thead-light">
                    <tr>
                        <th>Installation id</th>
                        <th>Address id</th>
                        <th>External installation id</th>
                        <th>Nam id</th>
                        <th>Vendr address id</th>
                        <th>Available</th>
                        <th>Vendor</th>
                        <th>Infrastructure owner</th>
                        <th>Installation code</th>
                        <th>Build state id</th>
                        <th>Technician needed</th>
                        <th>Changed date</th>
                        <th>Lead time</th>
                        <th>Technology</th>
                        <th>Speed up</th>
                        <th>Speed down</th>
                    </tr>
                </thead>
                <tbody>
                    {rowData.map((r, index) => <tr key={index} className={r.id === select ? "select-row" : ""}
                        onClick={() => setSelect(r.id)}>
                        <td>{r.installationId}</td>
                        <td>{r.madId}</td>
                        <td>{r.externalInstallationId}</td>
                        <td>{r.namId}</td>
                        <td>{r.vendorAddressId}</td>
                        <td>{r.available ? "Yes" : "No"}</td>
                        <td>{vendors.find(v => v.value === r.vendorId).name}</td>
                        <td>{r.infrastructureOwner}</td>
                        <td>{r.installationCode}</td>
                        <td>{r.buildStateId}</td>
                        <td>{r.technicianNeeded}</td>
                        <td>{r.changedDate ? r.changedDate.split("T")[0] : null}</td>
                        <td>{r.leadTime}</td>
                        <td>{r.technology}</td>
                        <td>{r.lineSpeedUp}</td>
                        <td>{r.lineSpeedDown}</td>
                    </tr>)}
                </tbody>
            </table>
        </div>
    )
}

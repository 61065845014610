import React from 'react';
import ReactJson from 'react-json-view';
import XmlBeautify from 'xml-beautify';

function HistoryModal({ history }) {

    const validateJson = (data) => {
        try {
            JSON.parse(data)
        } catch (e) {
            return false;
        }
        return true;
    }
    const decoder64 = (data) => decodeURIComponent(escape(atob(data)));

    const xmlFormatter = (data) => new XmlBeautify().beautify(decoder64(data), { indent: "  ", useSelfClosingElement: true });


    return (
        <table className="table table-striped table-sm table-bordered " style={{ textAlign: "left" }}>
            <thead>
                <tr>
                    <th scope="col">Response</th>
                </tr>
            </thead>
            <tbody>
                <tr>

                    <td>
                        {history && (validateJson(decoder64(history.res)) ? <ReactJson src={JSON.parse(decoder64(history.res))} indentWidth={6}
                            theme="monokai"
                            displayObjectSize={false}
                            displayDataTypes={false}
                            displayArrayKey={false}
                            enableClipboard={e => navigator.clipboard.writeText(JSON.stringify(e.src))}
                            name={false} /> :
                            <pre style={{ fontSize: "1rem" }}>{xmlFormatter(history.res)}</pre>)
                        }
                    </td>
                </tr>

            </tbody>
            <thead>
                <tr>
                    <th scope="col">Request</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        {history && (validateJson(decoder64(history.res)) ? <ReactJson src={JSON.parse(decoder64(history.req))}
                            theme="monokai" indentWidth={6}
                            displayObjectSize={false}
                            enableClipboard={e => navigator.clipboard.writeText(JSON.stringify(e.src))}
                            displayDataTypes={false}
                            displayArrayKey={false}
                            name={false} /> : <pre style={{ fontSize: '1rem' }}>{xmlFormatter(history.req)}</pre>
                        )
                        }
                    </td>
                </tr>
            </tbody>

        </table>
    );
}

export default React.memo(HistoryModal);
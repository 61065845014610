const BASEURL = window.__env__.REACT_APP_BASEURL;

export const urls = {
    prequalification: {
        url: `${BASEURL}/v1/prequalification`,
        method: 'POST', content: 'application/xml', accept: 'application/xml'
    },
    prequalificationV2:{
        url: `${BASEURL}/v2/prequalification`,
        method: 'POST', content: 'application/json', accept: 'application/json'
    },
    speedCalculation: {
        url: `${BASEURL}/v1/nuuVisitationValidator/speedCalculation`,
        method: 'POST', content: 'application/json', accept: 'application/json'
    },
    speedCalculationV2: {
        url: `${BASEURL}/v2/nuuVisitationValidator/speedCalculation`,
        method: 'POST', content: 'application/json', accept: 'application/json'
    },
    callBandwidthByAddress: {
        url: `${BASEURL}/v1/prequalification/callBandwidthByAddress`,
        method: 'POST', content: 'application/xml', accept: 'application/xml'
    },
    getVisitationByAddress: {
        url: `${BASEURL}/v1/prequalification/getVisitationByAddress`,
        method: 'POST', content: 'application/xml', accept: 'application/xml'
    },
    getVisitationBySubscriber: {
        url: `${BASEURL}/v1/prequalification/getVisitationBySubscriber`,
        method: 'POST', content: 'application/xml', accept: 'application/xml'
    },
    getTechParams: {
        url: `${BASEURL}/v1/nuuVisitationValidator/getTechParams`,
        method: 'POST', content: 'application/xml', accept: 'application/xml'
    }
}

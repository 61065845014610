import React, { useState } from "react";
import { useTable, useSortBy, useFilters } from 'react-table';
import { ReactTableFilter } from './ReactTableFilter';
import Modal from '../../common/modal/Modal';
import StagingModal from './StagingModal';
import ErrorBody from '../../common/error/ErrorBody';
import { getVendor, decodeStaging } from './util';

function ReactDataTable({ rowData }) {

  const [rowId, setRowId] = useState();
  const [modal, setModal] = useState({ title: 'title', data: '' });
  const [info, setInfo] = useState();
  const [error, setError] = useState();

  const setDetails = (data) => {
    setInfo("");
    setError("");
    setModal({ title: `Details`, data: '' });
    if (data) {
      setInfo(data);
    } else {
      setError("No data found");
    }
  }

  const data = React.useMemo(
    () => rowData ? rowData.map(data => {
      let byteToString = decodeURIComponent(escape(atob(data.jsonWhiteListObject))).toUpperCase();
      let jsonData = "";
      try {
        jsonData = JSON.parse(byteToString);
      } catch (err) {
        setError("Invalid json format for trans Id " + data.transactionId);
      }
      let vendor = getVendor(data);
      return {
        createdDate: data.createdTime.split("T")[0],
        kvhx: jsonData?.KVHX,
        resource: decodeStaging(jsonData, vendor).resource,
        installationId: decodeStaging(jsonData, vendor).installationId,
        avaiable: decodeStaging(jsonData, vendor).available,
        status: decodeStaging(jsonData, vendor).status,
        networkOwner: vendor,
        details: jsonData,
        transStatus: data.transStatus,
        madId: data.madId
      }
    }) : [],
    [rowData]
  )

  const columns = React.useMemo(
    () => [
      { Header: 'Created Date', Footer: 'Created Date', accessor: 'createdDate' },
      {
        Header: 'Details', Footer: 'Details', accessor: 'details',
        Cell: ({ value }) => <button className="btn btn-sm btn-secondary"
          data-toggle="modal" data-target='#tran-modal'
          onClick={() => setDetails(value)}>
          view
        </button>
      },
      { Header: 'KVHX', Footer: 'KVHX', accessor: 'kvhx' },
      { Header: 'Resource', Footer: 'Resource', accessor: 'resource' },
      { Header: 'Installation id', Footer: 'Installation id', accessor: 'installationId' },
      { Header: 'Avaiable', Footer: 'Avaiable', accessor: 'avaiable' },
      { Header: 'status', Footer: 'status', accessor: 'status' },
      { Header: 'Network owner', Footer: 'Network owner', accessor: 'networkOwner' },
      { Header: 'Trans status', Footer: 'Trans status', accessor: 'transStatus' },
      { Header: 'Mad id', Footer: 'Mad id', accessor: 'madId' }
    ],
    []
  )

  const defaultColumn = React.useMemo(() => {
    return {
      Filter: ReactTableFilter
    }
  }, [])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    prepareRow,
    rows
  } = useTable(
    {
      columns,
      data,
      defaultColumn
    },
    useFilters,
    useSortBy,
  )


  return (
    <>
      <div style={{ position: 'relative', overflow: 'scroll', textAlign: 'center', maxHeight: "70vh" }}>
        <button id="direct-Search" data-toggle="modal" data-target='#tran-modal' hidden></button>
        <Modal id="tran-modal" title={modal.title}
          res={info ? <StagingModal stagingData={info} /> : ''}
          error={error ? <ErrorBody errorMessage={error} /> : ''} />
        <table {...getTableProps()}
          className="table table-sm table-striped table-bordered">
          <thead className="thead-light">
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th key={column.Header}>
                    <span  {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                      {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                    </span>
                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row)
              return (
                <tr className={rowId === row.id ? 'select-row' : ''} {...row.getRowProps()} onClick={() => setRowId(row.id)}>
                  {row.cells.map(cell => {
                    return (
                      <td
                        {...cell.getCellProps()}
                      >
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
          <tfoot className="thead-light">
            {footerGroups.map(group => (
              <tr {...group.getFooterGroupProps()}>
                {group.headers.map(column => (
                  <th {...column.getFooterProps()}>{column.render("Footer")}</th>
                ))}
              </tr>
            ))}
          </tfoot>
        </table>
      </div>
    </>
  )
}

export default React.memo(ReactDataTable);
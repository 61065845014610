import React, {useEffect, useState} from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Home from '../components/home/Home';
import History from '../components/monitor/history/History';
import AccessInventory from '../components/monitor/ai/AccessInventory';
import Nav from '../components/common/nav/Nav';
import ServiceExecution from '../components/test/ServiceExecution';
import Loading from '../components/common/loading/Loading';
import ErrorBody from '../components/common/error/ErrorBody';
import Catalog from '../components/monitor/catalog/Catalog';
import Sigma from '../components/monitor/sigma/Sigma';
import Resource from '../components/management/resource/Resource';
import Qos from '../components/management/qos/Qos';
import Cpe from '../components/management/cpe/Cpe';
import Property from '../components/management/property/Property';
import Cache from '../components/management/cache/Cache';
import EnvInfo from '../components/management/envInfo/EnvInfo';
import Staging from '../components/monitor/staging/Staging';
import '../asset/css/app.css';
import {hasAccess} from '../api/management_api';
import MaxSpeed from '../components/monitor/speed/MaxSpeed';
import {AuthenticatedTemplate, UnauthenticatedTemplate} from "@azure/msal-react";
import Login from "../login/Login";

function App() {
    console.log('REACT_APP_ENVIRONMENT', window.__env__.REACT_APP_ENVIRONMENT);
    console.log('REACT_APP_BASEURL', window.__env__.REACT_APP_BASEURL);
    console.log('REACT_APP_BASEURL', window.__env__.REACT_APP_PING_AUTHORITY);
    console.log('REACT_APP_BASEURL', window.__env__.REACT_APP_PING_REDIRECT);

    return (
        <BrowserRouter>
            <AuthenticatedTemplate>
                <Nav>
                    <Routes>
                        <Route path='/home' element={<Home/>}/>
                        <Route path='/monitor/history' element={<History/>}/>
                        <Route path='/monitor/ai' element={<AccessInventory/>}/>
                        <Route path='/monitor/staging' element={<Staging/>}/>
                        <Route path='/monitor/catalog' element={<Catalog/>}/>
                        <Route path='/monitor/speed' element={<MaxSpeed/>}/>
                        <Route path='/monitor/sigma' element={<Sigma/>}/>
                        <Route path='/test/service' element={<ServiceExecution/>}/>
                        <Route path='/management/resource'
                               element={hasAccess() ? <Resource/> : <ErrorBody errorMessage="Access Denied"/>}/>
                        <Route path='/management/qos' element={<Qos/>}/>
                        <Route path='/management/property' element={<Property/>}/>
                        <Route path='/management/cpe' element={<Cpe/>}/>
                        <Route path='/management/env'
                               element={hasAccess() ? <EnvInfo/> : <ErrorBody errorMessage="Access Denied"/>}/>
                        <Route path='/management/cache'
                               element={hasAccess() ? <Cache/> : <ErrorBody errorMessage="Access Denied"/>}/>
                    </Routes>
                </Nav>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <Login/>
            </UnauthenticatedTemplate>
        </BrowserRouter>
    );
// }
    /*
    return
        <div>{error ? <ErrorBody errorMessage={error}/> : <Loading/>}</div>
    ;
    */
}


export default App;
